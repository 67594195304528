@import "../../styles/mixins.scss";

.header {
  // height: 90px;
  min-height: 90px;
  z-index: 4000;
  position: relative;
  background: #ffffff;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &__content {
    max-width: 1440px;
    width: 100%;
    padding: 0 16px;
    padding: 25px 120px;
    display: flex;
    justify-content: space-between;
    &__logo {
      width: 125px;
      @include respond-below(md) {
        width: 94px;
      }
    }
    @include respond-below(md) {
      padding: 16px;
    }
    &__tooltip {
      display: flex;
      flex-direction: column;
      font-style: normal;
      width: fit-content;

      a {
        color: #0a84ff;
        white-space: nowrap;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        @include respond-below(md) {
          font-size: 15px;
          line-height: 20px;
        }
      }
      span {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #777778;
        white-space: nowrap;
        @include respond-below(md) {
          font-size: 13px;
          line-height: 16px;
        }
      }
    }
    &__phone {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:hover {
        path {
          stroke: #1c1c1e;
        }
        span {
          color: #1c1c1e;
        }
      }
      svg {
        margin-right: 4px;
        path {
          transition: stroke 0.3s;
        }
      }
      span {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #777778;
        transition: color 0.3s;
      }
    }
  }
  &__content2 {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include respond-below(md) {
      margin-top: 56px;
    }
    &__img1 {
      margin-bottom: 40px;
      max-width: 600px;
      width: 100%;
      @include respond-below(md) {
        width: 310px;
      }
    }
    &__img2 {
      @include respond-below(md) {
        width: 727px;
      }
      @include respond-below(xs) {
        height: 65px;
        object-fit: cover;
      }
    }
  }
}

.tooltip {
  //   background: red;
  &::after,
  &::before {
    content: none !important;
  }
}
[class*="__react_component_tooltip"] {
  box-shadow: 0px 2px 8px rgba(28, 28, 30, 0.08);
  border-radius: 16px !important;
}
