@import "../../styles/mixins.scss";

.footer {
  z-index: 4000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  margin-bottom: 16px;
  &__copyright {
    white-space: pre-wrap;
    max-width: 1200px;
    padding: 0 16px;
    // display: flex;
    width: calc(100% - 32px);
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #b5b5b8;
    @include respond-below(md) {
      flex-direction: column;
      border-bottom: none;
    }
  }
}
