@import "../../../styles/variables";
@import "../../../styles/mixins";

.body {
  flex: 1;
}

.filter {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 80px;

  &__reset {
    font-size: 14px;
    background-color: transparent;
    padding: 0;
    border: 0;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    color: $color-gray-light;
    border-bottom: 1px solid $color-gray-light;
    line-height: 16px;
    cursor: pointer;
  }
}

.expanders {
  .expander:first-child {
    padding-top: 0;

    &:before,
    &:after {
      display: none;
    }
  }
}

.expander {
  padding: 24px 0 20px 0;
  display: flex;
  flex-direction: column;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }

  &__icon {
    transform: rotate(-90deg);
    color: $color-gray-light;
    margin-top: 1px;
  }

  &__button {
    padding: 0;
    cursor: pointer;
    width: 100%;
    background-color: $color-white;
    border: 0;
    display: flex;
    justify-content: space-between;
  }

  &__tags {
    padding-top: 14px;
    margin-bottom: -10px;
  }

  &__tag {
    margin: 0 10px 10px 0;

    i {
      color: $color-gray;
      font-size: 14px;
    }

    &__text {
      text-align: left;
    }
  }
}

.field {
  padding: 30px 0;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 0;
  @include respond-below(md) {
    padding-top: 16px;
  }
}
.titleText {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #777778;
  margin-top: 16px;
  margin-bottom: 8px;
}
.tabs {
  display: flex;
  overflow-x: auto;
  flex-shrink: 0;
  margin-bottom: 16px;
  @include respond-above(md) {
    padding-left: 20px;
    padding-right: 12px;
  }
  @include respond-below(md) {
    padding-left: 16px;
    padding-right: 8px;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &__tab {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #1c1c1e;
    display: flex;
    background: #f4f4f5;
    border-radius: 111px;
    padding: 7px 12px;
    margin-right: 8px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    &:hover {
      background: #ececf0;
    }
    &__active {
      background: #1c1c1e;
      color: #ffffff;
      &:hover {
        background: #1c1c1e;
      }
    }
  }
}
.card {
  display: flex;
  padding: 12px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(28, 28, 30, 0.08);
  border-radius: 12px;
  justify-content: space-between;
  margin-bottom: 8px;
  @include respond-below(md) {
    flex-direction: column;
  }
  &__name {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #1c1c1e;
  }
  &__info {
    @include respond-below(md) {
      margin-top: 8px;
    }
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0a84ff;
  }
}

.wrapper {
  display: flex;
  overflow-y: auto;
  flex-direction: column;
  padding: 4px;
  @include respond-above(md) {
    margin: 0 20px;
  }
  @include respond-below(md) {
    margin: 0 16px;
  }
  &__null {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #777778;
    margin-top: 16px;
    display: flex;
  }
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
  position: absolute;
  top: 0;
  padding: 20px;
  left: 0;
  @include respond-above(md) {
    width: 100%;
    border-bottom: 1px solid #ececf0;
  }
  @include respond-below(md) {
    padding: 16px;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
  }
}

.footer {
  display: flex;

  @include respond-below(md) {
    margin: 0 -20px -30px;
  }

  &__button {
    font-weight: 500;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: 4px;
      border-right-width: 0;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }

    &:active {
      top: 0;
    }
  }
}

.categories {
  padding-bottom: 20px;

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 20px;
  }

  &__button {
    padding: 0;
    font-weight: 500;
    min-width: 0;
    height: auto;
  }

  &__parent {
    line-height: 17px;
    display: block;
    margin-bottom: 10px;
  }

  &__current {
    display: block;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 4px;
    background-color: $color-gray-light-bg;
    margin-bottom: 20px;
  }
}

.actionsFixed {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0;

  @include respond-below(md) {
    display: block;
  }
}
.expenderWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.wrapperCategories {
  display: flex;
  flex-direction: column;
  text-align: left;
  div {
    padding: 10px 16px;
    background: #f9f9f9;
    border-radius: 5px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #363b45;
  }
  span {
    margin: 10px 0;
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #363b45;
  }
}

.modal_field {
  position: relative;
  width: 720px;
  border-radius: 18px;
  overflow: hidden;
  display: flex;
  padding: 20px 0;
  @include respond-below(md) {
    padding: 48px 0 30px !important;
	height: 100%;
  }
  // [class^='checkboxes_simple_bar'] {
  // 	height: 260px;
  // }
}
