@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.container {
  position: relative;
  @include respond-below(lg) {
    max-width: 100%;
  }
}

.arrow {
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  @include respond-below(lg) {
    left: 2px;
  }
  &_right {
    left: revert;
    right: -20px;
    @include respond-below(lg) {
      right: 2px;
    }
  }
}

.wrapper_single {
  display: grid;
  column-gap: 16px;
  grid-auto-flow: column;

  @include respond-above(lg) {
    grid-auto-columns: calc((100% - 4 * 16px) / 4);
    padding: 16px 18px 16px 18px;
    margin: -16px -8px -16px -8px;
  }

  @include respond-above(md) {
    overflow: hidden;
  }

  // @include respond-below(lg) {
  //   grid-auto-columns: calc((100% - 3 * 16px) / 3);
  // }
  // @include respond-below(md) {
  //   grid-auto-columns: calc((100% - 2.5 * 16px) / 2.5);
  // }
  // @include respond-below(sm) {
  //   grid-auto-columns: calc((100% - 1.8 * 16px) / 1.8);
  // }
  // @include respond-below(xs) {
  //   grid-auto-columns: calc((100% - 16px) / 1);
  // }

  // @include respond-below(sm) {
  //   grid-auto-columns: calc((100% - 2.5 * 8px) / 2.5);
  // }
}

.wrapper_double,
.wrapper_single {
  @include respond-below(md) {
    @include hide-scroll;
    column-gap: 8px;
    overflow-x: scroll;
    margin: -14px 0;
    padding: 14px 0;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.block {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 32px;
  background: #ffffff;
  border-radius: 24px;
  margin-top: 24px;
  align-items: center;
  @include respond-below(lg) {
    overflow: hidden;
  }
  @include respond-below(sm) {
    padding: 24px;
    margin-top: 16px;
  }
  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    color: #1c1c1e;
    text-align: center;
    @include respond-below(sm) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  &__body {
    margin-top: 32px;
    width: 100%;
    @include respond-below(sm) {
      margin-top: 24px;
    }
  }
}

.card {
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(28, 28, 30, 0.08);
  border-radius: 16px;
  padding: 12px 16px;
  height: fit-content;
  margin: auto 0;
  width: fit-content;
  height: 100%;
  &__superDraws {
    border: 1px solid #0a84ff;
  }
  &__winner {
    background: #f4f4f5;
    box-shadow: none;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 4px;
    border-bottom: 1px solid #ececf0;
    &__left {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #1c1c1e;
      @include respond-below(sm) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    &__rigth {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #777778;
      @include respond-below(sm) {
        font-size: 13px;
        line-height: 16px;
      }
      &__blue {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #0a84ff;
        display: flex;
        align-items: center;
        cursor: pointer;
        i {
          font-size: 12px;
          margin-left: 2px;
          transform: rotate(-90deg);
        }
      }
    }
  }
  &__body {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #777778;
    display: flex;
    flex-direction: column;
    @include respond-below(sm) {
      font-size: 13px;
      line-height: 16px;
    }
    span {
      margin-top: 4px;
      display: flex;
      flex-shrink: 0;
      white-space: nowrap;
    }
    &__super {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #0a84ff;
    }
  }
}

.padding {
  padding: 32px 0;
}
.expanderWrap {
  [class*="block__body"] {
    flex-direction: column;
  }
  .expander {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(28, 28, 30, 0.08);
    border-radius: 16px;
    padding: 0 16px;
    margin-bottom: 16px;
    i {
      color: #b5b5b8;
      transition: color 0.3s;
    }
    &::after,
    &::before {
      content: none;
    }
    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #1c1c1e;
    }
    [class*="expander__header"] {
      [class*="expander__icon_expand"] {
        color: #1c1c1e;
      }
      &:hover {
        i {
          color: #1c1c1e;
        }
        span {
          color: #1c1c1e;
        }
      }
    }
    &__desc {
      // white-space: pre-wrap;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #777778;
      ul {
        margin: 0;
        padding: 0;
        padding-inline-start: 30px;
        margin-block: 0;
      }
      a,
      span {
        color: #0a84ff;
        cursor: pointer;
      }
    }
  }
}

.prizes {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: -28px;
  @media (max-width: 800px) {
    margin-left: 0;
    flex-direction: column;
  }
  &__card {
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    @media (max-width: 800px) {
      margin: 0;
      margin-bottom: 16px;
      padding-bottom: 16px;
      max-width: initial;
      width: 100%;
      border-bottom: 1px solid #ececf0;
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  &__icon {
    height: 25px;
    display: flex;
    flex-shrink: 0;
    margin-bottom: 4px;
    @include respond-below(sm) {
      height: 22px;
    }
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 41px;
    text-align: center;
    color: #0a84ff;
    @include respond-below(sm) {
      font-size: 24px;
      line-height: 28px;
    }
  }
  &__desc {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #0a84ff;
    margin-top: 2px;
    @include respond-below(sm) {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
.brands {
  a {
    &:nth-child(8n) {
      .brands__card {
        margin-right: 0 !important;
      }
    }
  }
  &__block {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(8, 128px);
    padding-left: 32px;
    padding-top: 5px;
    padding-right: 32px;
    overflow-x: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    @include respond-below(sm) {
      column-gap: 8px;
      grid-template-columns: repeat(8, 121px);
    }
  }
  &__card {
    width: 121px;
    height: 88px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(28, 28, 30, 0.08);
    border-radius: 16px;
    margin-right: 0;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond-below(sm) {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #777778;
    margin: auto;
    display: flex;
    width: fit-content;
    margin-top: 3px;
    @include respond-below(sm) {
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.rules {
  display: flex;
  flex-direction: column;
  &__block {
    display: flex;
    justify-content: space-between;
    @include respond-below(md) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    margin: 0 12px;
    @include respond-below(md) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      max-width: initial;
      width: 100%;
      border-bottom: 1px solid #ececf0;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__badge {
      width: 48px;
      height: 48px;
      background: #f4f4f5;
      border-radius: 111px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include respond-below(sm) {
        width: 32px;
        height: 32px;
        margin-bottom: 8px;
      }
      &::after {
        content: attr(data-number);
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #1c1c1e;
        @include respond-below(sm) {
          font-size: 17px;
          line-height: 22px;
        }
      }
    }
    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: #1c1c1e;
      margin-bottom: 4px;
      @include respond-below(sm) {
        font-size: 15px;
        line-height: 20px;
      }
    }
    &__desc {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #777778;
      a {
        color: #0a84ff;
        cursor: pointer;
      }
      span {
        color: #0a84ff;
        cursor: pointer;
      }
      @include respond-below(sm) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #777778;
    margin: auto;
    display: flex;
    width: fit-content;
    margin-top: 24px;
    i {
      margin-right: 8px;
    }
    @include respond-below(sm) {
      margin-top: 24px;
      font-size: 15px;
      line-height: 20px;
    }
  }
}
